<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">The reverse reaction of the Haber process is shown below:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 NH3(g) <=> N2(g) + 3 H2(g)" />
      </p>

      <p class="mb-3">
        If the concentrations of each of the substances at equilibrium are found to be
        <chemical-latex content="[NH3]=" />
        <number-value :value="NH3conc" unit="\text{M,}" />
        <chemical-latex content="[H2]=" />
        <number-value :value="H2conc" unit="\text{M,}" />
        and
        <chemical-latex content="[N2]=" />
        <number-value :value="N2conc" unit="\text{M,}" />
        determine the equilibrium constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question114',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    NH3conc() {
      return this.taskState.getValueBySymbol('NH3conc').content;
    },
    H2conc() {
      return this.taskState.getValueBySymbol('H2conc').content;
    },
    N2conc() {
      return this.taskState.getValueBySymbol('N2conc').content;
    },
  },
};
</script>
